import React from 'react'
import { graphql } from 'gatsby'
import BannerTitleOnlyBlock from 'components/blocks/BannerTitleOnlyBlock'
import SingleImageProductBlock from 'components/blocks/SingleImageProductBlock'
import BannerBlock from 'components/blocks/BannerBlock'
import BodyBlock from 'components/blocks/BodyBlock'
import SingleImageBlock from 'components/blocks/SingleImageBlock'
import MoreItemsBlock from 'components/blocks/MoreItemsBlock'
import { getNextItems } from 'js/utils'

export default function DiamondPost({ data }) {
  const page = data.strapiDiamond
  if (!page) return false
  const { title, image, diamondPostBuilder } = page
  const parent = data.strapiDiamondsPage
  const customBanner = {
    pretitle: parent.title,
    title,
  }

  const diamonds = data.allStrapiDiamond.nodes
  let nextDiamonds
  if (diamonds) {
    nextDiamonds = getNextItems(diamonds, page.slug, 4)
  }

  return (
    <div className='page-diamond-post'>
      <BannerTitleOnlyBlock
        content={customBanner}
        padTop={true}
        lineBottom={true}
      />
      <SingleImageProductBlock content={{ page }} />

      {diamondPostBuilder && <Post content={diamondPostBuilder} />}
      {nextDiamonds && (
        <MoreItemsBlock items={nextDiamonds} version='diamond' />
      )}
    </div>
  )
}

const Post = ({ content }) => {
  const { bodyBlock, singleImageBlock } = content

  return (
    <>
      {bodyBlock && <BodyBlock content={bodyBlock} isDiamond={true} />}
      {singleImageBlock && (
        <SingleImageBlock content={singleImageBlock} isBigger={true} />
      )}
    </>
  )
}

export const pageQuery = graphql`
  query ($locale: String!, $slug: String!) {
    strapiDiamondsPage(locale: { eq: $locale }) {
      title
    }
    strapiDiamond(slug: { eq: $slug }, locale: { eq: $locale }) {
      slug
      title
      seo {
        title
        description
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      diamondPostBuilder {
        bodyBlock {
          title
          body
        }
        singleImageBlock {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allStrapiDiamond(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        excerpt
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
